import axios from "axios";
import { ReactProjectBaseUrl } from "../Constants";
import Cookies from "js-cookie";
import { logout } from "../logout";
import { globalSwalFunction } from "./globalSwal";
import { useNavigate } from "react-router-dom";

export const axiosFunction = async (
  token,
  url,
  method,
  body = null,
  accessToken = null
) => {
  if (token === undefined) return;

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    API_TOKEN: token,
    "Access-Control-Allow-Origin": "*",
  };

  // Conditionally add the Authorization header if the token is defined
  if (accessToken) {
    headers["Authentication"] = `${accessToken}`;
  }

  try {
    const response = await axios({
      url,
      method,
      headers,
      data: method === "POST" || "PUT" ? body : null,
      params: method === "GET" ? body : null,
    });

    return response;
  } catch (error) {
    const refreshToken = Cookies.get("refreshToken");
    try {
      if (error?.response?.status === 401) {
        const res = await axios.get(
          `${ReactProjectBaseUrl}/refresh-token?refreshToken=${refreshToken}`
        );

        if (res?.status == 200) {
          Cookies.set("accessToken", res?.data?.accessToken);
          Cookies.set("refreshToken", res?.data?.refreshToken);
        }
        window.location.reload(true);
      }
    } catch (error) {
      console.log("error", error);

      if (error?.response?.status === 403) {
        const result = logout();
        globalSwalFunction(
          "User Logout",
          '<i class="fas fs-5 fa-check-circle text-success "></i>'
        );
        if (result) {
          window.location.href = "/";
        }
      }
    }
  }
};
