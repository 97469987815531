import { ActionTypes } from "../../State/constants/ActionTypes";
import { ReactProjectBaseUrl } from "../Constants";
import { API_ENDPOINTS } from "../api-endpoints";
import {
  getApiToken,
  getBranchToken,
  isGeofenchingEnabled,
} from "../get-token";
import { defaultImage } from "../productUtils/ProductUtils";
import { axiosFunction } from "./axiosFunction";
import { globalSwalFunction } from "./globalSwal";

let isStockUpdateInProgress = false; // Flag to prevent repeated calls

export const realTimeStockUpdateFunc = (cartItems, decimalPoints, dispatch) => {
  const token = isGeofenchingEnabled() == 1 ? getBranchToken() : getApiToken();
  if (isStockUpdateInProgress) {
    // Prevent recursive calls
    return;
  }

  isStockUpdateInProgress = true;

  const updatedCartItem = [...cartItems];

  axiosFunction(token, ReactProjectBaseUrl + API_ENDPOINTS.CART_LIST, "POST", {
    cartDTOs: updatedCartItem,
  })
    .then((res) => {
      if (res.status) {
        const saveApiResponse = res.data.response?.cartDTOs;

        if (saveApiResponse?.length > 0) {
          const updatedCartItems = saveApiResponse.reduce((acc, item) => {
            const cartItem = cartItems.find(
              (productItem) =>
                productItem.productVarientId === item.productVarientId
            );

            if (!cartItem) {
              return acc;
            }

            // console.log("item.availableQuantity", item.availableQuantity);
            // console.log("cartItem.quantity", cartItem.quantity);
            // const latestQuantity = Math.min(
            //   item.availableQuantity < 0 ? 0 : item.availableQuantity,
            //   cartItem.quantity
            // );

            // console.log("latestQuantity", item);
            // console.log("cartItem", cartItem);

            // const latestQuantity =
            //   cartItem.quantity > item.availableQuantity
            //     ? item.availableQuantity
            //     : cartItem.quantity;
            if (item.availableQuantity <= 0) {
              acc.push({
                productVarientId: item.productVarientId,
                price: item.sellingprice,

                quantity: 0,
                // quantity: cartItem.quantity > 0 ? cartItem.quantity : 1,
                imgSrc: item.imgSrc || defaultImage,
                productName: item.name,
                productid: item.productId,
                isFromBuyNow: 0,
                availableQuantity: Math.floor(item.availableQuantity),
                variantName: item.variantName,
                activeOnline: item.activeOnline,
              });
            } else {
              acc.push({
                productVarientId: item.productVarientId,
                price: item.sellingprice,
                quantity:
                  item.availableQuantity <= cartItem.quantity
                    ? item.availableQuantity
                    : cartItem.quantity === 0
                    ? 1
                    : cartItem.quantity,
                // quantity: cartItem.quantity > 0 ? cartItem.quantity : 1,
                imgSrc: item.imgSrc || defaultImage,
                productName: item.name,
                productid: item.productId,
                isFromBuyNow: 0,
                availableQuantity: Math.floor(item.availableQuantity),
                variantName: item.variantName,
                activeOnline: item.activeOnline,
              });
            }

            return acc;
          }, []);

          const compareCartProduct = cartItems.every((product) => {
            return updatedCartItems.some((revampProduct) => {
              return (
                // updatedCartItem.price !== cartItem.price ||
                // updatedCartItem.quantity !== cartItem.quantity ||
                // updatedCartItem.availableQuantity !== cartItem.availableQuantity ||
                revampProduct.activeOnline === product.activeOnline &&
                revampProduct.availableQuantity >= product.quantity &&
                revampProduct.price === product.price
              );
            });
          });
          if (!compareCartProduct) {
            globalSwalFunction(
              "There are some changes in your cart",
              '<i class="fas fa-times-circle text-danger fs-5"></i>'
            );
          }

          dispatch({
            type: ActionTypes.ADD_TO_CART,
            payload: updatedCartItems,
          });
          // console.log(updatedCartItems, "updatedCartItems");
        }
      }
    })
    .catch((error) => {
      throw new Error(error);
    })
    .finally(() => {
      isStockUpdateInProgress = false;
    });
};
